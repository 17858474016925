import React, { Fragment } from "react"

import Layout from "../components/Layout"
import { TermsWrapper } from "../components/Style/terms"
import Seo from "../components/Seo"

const TermsPage = () => {
  return (
    <Fragment>
      <Seo
        title={`Terms & Conditions`}
        description={`Please read these terms and conditions (the 'Terms') carefully. By using, browsing and/or reading the Website, this signifies that you have read, understood and agree to be bound by the Terms.`}
      />

      <Layout>
        <TermsWrapper>
          <h1>Terms & Conditions</h1>

          <section>
            <ol>
              <li>
                <h2>About the Website</h2>
                <p>1.1. Welcome to the Flexi Tennis Leagues (the 'Website').</p>
                <p>
                  1.2. The Website operates under ABN 46 285 390 502. Access to and use of the Website, or any of its associated Services, is provided by Flexi
                  Tennis Leagues. Please read these terms and conditions (the 'Terms') carefully. By using, browsing and/or reading the Website, this signifies
                  that you have read, understood and agree to be bound by the Terms. If you do not agree with the Terms, you must cease usage of the Website, or
                  any of the Services, immediately.
                </p>
              </li>
              <li>
                <h2>Acceptance of the Terms</h2>
                <p>
                  You accept the Terms by remaining on the Website. You may also accept the Terms by clicking to accept or agree to the Terms where this option
                  is made available to you by Flexi Tennis Leagues.
                </p>
              </li>
              <li>
                <h2>Copyright and Intellectual Property</h2>
                <p>
                  3.1. The Website, the Services and all of the related products of Flexi Tennis Leagues are copyright. The material on the Website is protected
                  by copyright under the laws of Australia and through international treaties. Unless otherwise indicated, all rights (including copyright) in
                  the Services and compilation of the Website (including but not limited to text, graphics, logos, button icons, video images, audio clips,
                  Website, code, scripts, design elements and interactive features) or the Services are owned or controlled for these purposes, and are reserved
                  by Flexi Tennis Leagues or its contributors.
                </p>
                <p>3.2. All trademarks, service marks and trade names are owned, registered and/or licensed by Flexi Tennis Leagues.</p>
                <p>
                  3.3. Flexi Tennis Leagues retains all rights, title and interest in and to the Website and all related Services. Nothing you do on or in
                  relation to the Website will transfer any: <br />
                  (a) business name, trading name, domain name, trade mark, industrial design, patent, registered design or copyright, or <br />
                  (b) a right to use or exploit a business name, trading name, domain name, trade mark or industrial design, or <br />
                  (c) a thing, system or process that is the subject of a patent, registered design or copyright (or an adaptation or modification of such a
                  thing, system or process), to you.
                </p>
              </li>
              <li>
                <h2>Privacy</h2>
                <p>
                  4.1. Flexi Tennis Leagues takes your privacy seriously and any information provided through your use of the Website and/or Services are
                  subject to Flexi Tennis Leagues Privacy Policy, which is available on the Website.
                </p>
              </li>
              <li>
                <h2>General Disclaimer </h2>
                <p>
                  5.1. Nothing in the Terms limits or excludes any guarantees, warranties, representations or conditions implied or imposed by law, including
                  the Australian Consumer Law (or any liability under them) which by law may not be limited or excluded.
                </p>
                <p>
                  5.2. Subject to this clause, and to the extent permitted by law: <br />
                  (a) all terms, guarantees, warranties, representations or conditions which are not expressly stated in the Terms are excluded; and <br />
                  (b) Flexi Tennis Leagues will not be liable for any special, indirect or consequential loss or damage (unless such loss or damage is
                  reasonably foreseeable resulting from our failure to meet an applicable Consumer Guarantee), loss of profit or opportunity (including
                  cancelled tennis matches arising from a player’s injury or other circumstantial reasons), or damage to goodwill arising out of or in
                  connection with the Services or these Terms (including as a result of not being able to use the Services or the late supply of the Services),
                  whether at common law, under contract, tort (including negligence), in equity, pursuant to statute or otherwise.
                </p>
                <p>
                  5.3. Use of the Website and the Services is at your own risk. Everything on the Website and the Services is provided to you "as is" and "as
                  available" without warranty or condition of any kind. None of the affiliates, directors, officers, employees, agents, contributors and
                  licensors of Flexi Tennis Leagues make any express or implied representation or warranty about the Services or any products or Services
                  (including the products or services of Flexi Tennis Leagues) referred to on the Website. This includes (but is not restricted to) loss or
                  damage you might suffer as a result of any of the following: <br />
                  (a) failure of performance, error, omission, interruption, deletion, defect, failure to correct defects, delay in operation or transmission,
                  computer virus or other harmful component, loss of data, communication line failure, unlawful third party conduct, or theft, destruction,
                  alteration or unauthorized access to records; <br />
                  (b) the accuracy, suitability or currency of any information on the Website, the Services, or any of its Services related products (including
                  third party material and advertisements on the Website); <br />
                  (c) costs incurred as a result of you using the Website, the Services or any of the products of Flexi Tennis Leagues and <br />
                  (d) the Services or operation in respect to links which are provided for your convenience.
                </p>
              </li>
              <li>
                <h2>Limitation of liability </h2>
                <p>
                  6.1. Flexi Tennis Leagues total liability arising out of or in connection with the Services or these Terms, however arising, including under
                  contract, tort (including negligence), in equity, under statute or otherwise, will not exceed the resupply of the Services to you.
                </p>
                <p>
                  6.2. You expressly understand and agree that Flexi Tennis Leagues, its affiliates, employees, and agents shall not be liable to you for any
                  direct, indirect, incidental, special consequential or exemplary damages which may be incurred by you, however caused and under any theory of
                  liability. This shall include, but is not limited to, any loss of profit (whether incurred directly or indirectly), any loss of goodwill or
                  business reputation and any other intangible loss.
                </p>
              </li>
              <li>
                <h2>Indemnity</h2>
                <p>
                  7.1. You agree to indemnify Flexi Tennis Leagues, its affiliates, employees, agents, contributors, third party content providers and licensors
                  from and against: <br />
                  (a) all actions, suits, claims, demands, liabilities, costs, expenses, loss and damage (including legal fees on a full indemnity basis)
                  incurred, suffered or arising out of or in connection with Your Content; <br />
                  (b) any direct or indirect consequences of you accessing, using or transacting on the Website or attempts to do so; and/or
                  <br />
                  (c) any breach of the Terms.
                </p>
              </li>
              <li>
                <h2>Player Conduct and Commitment</h2>
                <p>
                  8.1. By registering and participating in a league, you agree to the following: <br />
                  (a) act with integrity by following the rules set and accepted by the parties involved in the match and being honest in all aspects of play.{" "}
                  <br />
                  (b) Showing consideration to other players by: <br />- attending matches at the agreed time, location and date specified by both parties or
                  informing them if your plan changes ahead of time; <br />- looking out for each other’s safety; <br />- providing adequate response time for
                  any messages and or updates in relation to facilitating the scheduling of matches and results; <br />
                  - refraining from utilizing inappropriate language (including but not limited to spamming, recreational drugs, sexually explicit language,
                  solicitation, racism or other prejudice, threatening behavior, blackmail and excessive swearing) when using the in-app chat system or in
                  person; and/or <br />
                  (c) ensuring you inform all involved parties in your current season fixture in an event of an injury or other circumstantial reason that would
                  not allow you to continue to participate in the league.
                </p>
              </li>
              <li>
                <h2>Termination</h2>
                <p>
                  If for any reason Flexi Tennis Leagues suspects you to breach any of the Terms or Privacy outlined on our website and or engage in any
                  unlawful acts that impacts the wellbeing of other players, we reserve the right to terminate your account and may disclose your Personal Data
                  to the local authorities.
                </p>
              </li>
              <li>
                <h2>Governing Law</h2>
                <p>
                  The Terms are governed by the laws of Western Australia, Australia. Any dispute, controversy, proceeding or claim of whatever nature arising
                  out of or in any way relating to the Terms and the rights created hereby shall be governed, interpreted and construed by, under and pursuant
                  to the laws of Western Australia, Australia, without reference to conflict of law principles, notwithstanding mandatory rules. The validity of
                  this governing law clause is not contested. The Terms shall be binding to the benefit of the parties hereto and their successors and assigns.
                </p>
              </li>
              <li>
                <h2>Independent Legal Advice</h2>
                <p>
                  Both parties confirm and declare that the provisions of the Terms are fair and reasonable and both parties having taken the opportunity to
                  obtain independent legal advice and declare the Terms are not against public policy on the grounds of inequality or bargaining power or
                  general grounds of restraint of trade.
                </p>
              </li>
              <li>
                <h2>Severance</h2>
                <p>
                  If any part of these Terms is found to be void or unenforceable by a Court of competent jurisdiction, that part shall be severed and the rest
                  of the Terms shall remain in force.
                </p>
              </li>
            </ol>
          </section>
        </TermsWrapper>
      </Layout>
    </Fragment>
  )
}

export default TermsPage

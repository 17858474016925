import styled from "styled-components"

export const TermsWrapper = styled.div`
  padding: 20px 0;
  background-color: white;

  h1 {
    padding-left: 100px;
    padding-right: 100px;
    margin-bottom: 40px;
  }
  section {
    padding: 0 100px 30px 100px;

    ol {
      font-size: 24px;

      h2 {
        font-size: 24px;
        margin-bottom: 0;
      }
    }
  }

  @media (max-width: 768px) {
    h1 {
      padding-left: 30px;
      padding-right: 30px;

      + p {
        padding-left: 30px;
        padding-right: 30px;
      }
    }
    section {
      padding: 30px 30px;
    }
  }
  @media (max-width: 350px) {
    h1 {
      font-size: 28px;
    }
  }
`
